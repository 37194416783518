<template>
  <div class="content">
    <sub-title>출석체크</sub-title>
    <div class="coupon_warp">

      <!--실버-->
      <div class="coupon">
        <div class="card_container" @click="openCoupon('s', $event)">
          <div class="cover">
            <img src="../../../assets/images/coupon/silver.cover.png" alt="">
          </div>
          <div class="back">
            <img src="../../../assets/images/coupon/silver.back.png" alt="">
          </div>
          <div class="couponpoint"></div>
          <div class="cuponcount">
            {{ $store.state.userInfo.scoupon }}장
          </div>
        </div>

      </div>

      <!--골드-->
      <div class="coupon">
        <div class="card_container" @click="openCoupon('g', $event)">
          <div class="cover">
            <img src="../../../assets/images/coupon/gold.cover.png" alt="">
          </div>
          <div class="back">
            <img src="../../../assets/images/coupon/gold.back.png" alt="">
          </div>
          <div class="couponpoint">/div>
          </div>
          <div class="cuponcount">
            {{ $store.state.userInfo.gcoupon }}장
          </div>
        </div>

      </div>

      <!--다이아-->
      <div class="coupon">
        <div class="card_container" @click="openCoupon('d', $event)">
          <div class="cover">
            <img src="../../../assets/images/coupon/dia.cover.png" alt="">
          </div>
          <div class="back">
            <img src="../../../assets/images/coupon/dia.back.png" alt="">
          </div>
          <div class="couponpoint">/div>
          </div>
          <div class="cuponcount">
            {{ $store.state.userInfo.dcoupon }}장
          </div>
        </div>
      </div>

    </div>

    <div class="culcheck_history_box">

      <table class="utable">
        <tr>
          <td colspan="2">쿠폰 획득내역</td>
        </tr>
        <tr>
          <th>시간</th>
          <th>내역</th>
        </tr>
        <tr v-for="(item,index) in couponGetHistory">
          <td>{{ item.createTime|datef('YYYY-MM-DD HH:mm') }}</td>
          <td>{{ item.content }}</td>
        </tr>
      </table>

      <table class="utable">
        <tr>
          <td colspan="2">쿠폰 사용내역</td>
        </tr>
        <tr>
          <th>시간</th>
          <th>내역</th>
        </tr>
        <tr v-for="(item,index) in couponUseHistory">
          <td>{{ item.createTime|datef('YYYY-MM-DD HH:mm') }}</td>
          <td>{{ item.content }}</td>
        </tr>
      </table>
    </div>


  </div>

</template>


<script>

import {getCulcheck} from "@/network/userRequest";
import {checkLoginOnCreateMinxin, postionMixin} from "@/common/mixin";
import sportsConst from "@/common/sportsConst";
import SubTitle from "@/views/afront/zero/SubTitle.vue";
import {getCouponConfig, getCouponHistory, openCoupon} from "@/network/leisureRequest";

export default {
  name: "Coupon",
  mixins: [postionMixin, checkLoginOnCreateMinxin],
  components: {SubTitle},
  data() {
    return {
      sportsConst,
      couponGetHistory: [],
      couponUseHistory: [],
      position: "쿠폰",
      config: null,
    }
  },
  methods: {
    openCoupon(type, event) {
      let cover = event.currentTarget.querySelector('.cover');
      let back = event.currentTarget.querySelector('.back');
      let couponpoint = event.currentTarget.querySelector('.couponpoint');
      let cuponcount = event.currentTarget.querySelector('.cuponcount');
      openCoupon(type).then(res => {
        if (res.data.success) {

          cover.style.transform = 'rotateY(180deg)'
          back.style.transform = 'rotateY(0deg)'
          couponpoint.innerText = this.$root.$options.filters.comma(res.data.data)
          couponpoint.style.opacity = '1'
          cuponcount.style.opacity = '0'

          setTimeout(() => {
            cover.style.transform = 'rotateY(0deg)'
            back.style.transform = 'rotateY(-180deg)'
            couponpoint.style.opacity = '0'
            cuponcount.style.opacity = '1'
            couponpoint.innerText = ''
          }, 1000)
          this.$store.dispatch('actionUserInfo')
          this.initHistory()
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })


    },

    open(type) {
      openCoupon(type).then(res => {
        if (res.data.success) {


          this.$swal({
            title: ' ' + res.data.data + '포인트 추가되였습니다',
            type: 'success',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
          this.$store.dispatch('actionUserInfo')
          this.initHistory()
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    initHistory() {
      getCouponHistory().then(res => {
        if (res.data.success) {
          this.couponGetHistory = res.data.data['getHistory']
          this.couponUseHistory = res.data.data['useHistory']
        }
      })
    },
    getCouponConfig() {
      getCouponConfig().then(res => {
        if (res.data.success) {
          this.config = res.data.data
        }
      })
    }
  },
  created() {
    this.initHistory();
    this.getCouponConfig()
  },
}
</script>

<style scoped>
@import url("../../../assets/css/front/afrontcoupon.css");


</style>